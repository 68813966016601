import React, { useState } from "react";
import { useEffect } from "react";
import { toast, Toaster } from "react-hot-toast";
import {
  getAdminByID,
  getAllSeekersMembershipDetails,
  getAllVerifiedSeekers,
  updateDocument,
  updateUserMembership,
} from "../../apis/user";
import Card from "./Card";

function AdminDashboard() {
  const [seekers, setSeekers] = useState([]);
  const [verfiedSeekers, setVerfiedSeekers] = useState([]);
  //   const [givers, setGivers] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);
  const [seekersExpanded, setSeekersExpanded] = useState(false);
  const [verifiedSeekersExpanded, setVerifiedSeekersExpanded] = useState(false);

  // aunthenticate admin
  useEffect(() => {
    (async () => {
      const dbResponse = await getAdminByID();
      if (dbResponse.success) {
        dbResponse.data.isMember && setIsAdmin(true);
        setLoading(false);
      }
    })();
  });
  async function getJobSeekers() {
    const dbResponse = await getAllSeekersMembershipDetails();
    if (dbResponse.success) {
      setSeekers(dbResponse.data);
      setSeekersExpanded(!seekersExpanded);
    } else {
      // if not a member
      toast.error(dbResponse.message);
      setSeekersExpanded(false);
      //   window.location.href = '/dashboard'
    }
  }

  async function getVerifiedSeekers() {
    const dbResponse = await getAllVerifiedSeekers();
    if (dbResponse.success) {
      setVerfiedSeekers(dbResponse.data);
      setVerifiedSeekersExpanded(!verifiedSeekersExpanded);
    } else {
      // if not a member
      toast.error(dbResponse.message);
      setVerifiedSeekersExpanded(false);
      //   window.location.href = '/dashboard'
    }
  }

  //   async function getJobGivers() {
  //     const dbResponse = await getAllGiversMembershipDetails();

  //     if (dbResponse.success) {
  //       setGivers(dbResponse.data);
  //       setGiversExpanded(!giversExpanded);
  //     } else {
  //       // if not a member
  //       toast.error(dbResponse.message);
  //       setGiversExpanded(false);
  //       //   window.location.href = '/dashboard'
  //     }
  //   }

  const deleteUser = async (index) => {
    const newData = [...seekers];
    console.log();
    newData.splice(index, 1);
    setSeekers(newData);
    try {
      await updateDocument(newData);
    } catch (error) {
      toast.error(error.message);
      console.error("Error deleting ", error);
    }
  };
  const verifyUser = async (e, id) => {
    // const parent = e.target.closest("#seekers");
    const payload = { isMember: true };
    try {
      const response = await updateUserMembership(payload, id);
      if (response.success) {
        toast.success(response.message);
        // remove it from seekers array
        const newSeekers = seekers.filter((el) => el.id !== id);
        setSeekers(newSeekers);
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      console.log("error");
      toast.error(error);
    }
  };

  return (
    <>
      <Toaster toastOptions={{ duration: 4000 }} />
      {loading ? (
        <div>Loading...</div>
      ) : isAdmin ? (
        <div className="flex flex-col  space-y-4">
          {/* all job seekers  */}
          <button
            className="block bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded my-4"
            onClick={getJobSeekers}
          >
            {!seekersExpanded
              ? `Get all unverified job seekers`
              : `Hide all unverified job seekers`}
          </button>
          {seekersExpanded && (
            <div className="space-y-8 " id="seekers">
              {seekers.length > 0 &&
                seekers.map((profile, index) => (
                  <Card
                    key={index}
                    id={profile.id}
                    data={profile.membershipDetails}
                    phoneNumber={profile.phoneNumber}
                    onDelete={() => deleteUser(index)}
                    verifyUser={verifyUser}
                  />
                ))}
            </div>
          )}

          {/* all job givers  */}
          {/* <button
            className=" block bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            onClick={getJobGivers}
          >
            {!giversExpanded
              ? `Get All Job providers`
              : `Hide All Job providers`}
          </button>
          {giversExpanded && (
            <div className="space-y-8 " id="givers">
              {givers.length > 0 &&
                givers.map((profile, index) => (
                  <Card
                    key={index}
                    id={profile.id}
                    data={profile.membershipDetails}
                    phoneNumber={profile.phoneNumber}
                    deleteUser={deleteUser}
                    verifyUser={verifyUser}
                  />
                ))}
            </div>
          )} */}

          {/* all verified job seekers  */}
          <button
            className=" block bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            onClick={getVerifiedSeekers}
          >
            {!verifiedSeekersExpanded
              ? `Get All Verified members`
              : `Hide All verified members`}
          </button>
          {verifiedSeekersExpanded && (
            <div className="space-y-8" id="verified-seekers">
              {verfiedSeekers.length > 0 &&
                verfiedSeekers.map((profile, index) => (
                  <Card
                    key={index}
                    id={profile.id}
                    isMember={profile.isMember}
                    data={profile.membershipDetails}
                    phoneNumber={profile.phoneNumber}
                  />
                ))}
            </div>
          )}
        </div>
      ) : (
        <h2>
          You are not an Admin. Please register as job Seeker or job Provider.
          If you are indeed an Admin, your admin rights are not set properly at
          the server
        </h2>
      )}
    </>
  );
}
export default AdminDashboard;
