import React from "react";

function JobSeekerCard({ index, data }) {
  return (
    <div className="bg-white rounded-lg shadow-md p-6 flex flex-col md:flex-row gap-5 md:gap-10 justify-between items-center ">
      {/* //name  */}
      <h2 className="text-2xl font-bold">{data.name}</h2>
      {/* tags  */}
      <div className="text-center">
        {data.tags.map((tag) => (
          <span
            key={tag}
            className="bg-gray-200 text-gray-800 rounded-full py-1 px-3 text-xs font-semibold mr-2 mb-2 inline-block"
          >
            {tag}
          </span>
        ))}
      </div>
      <div className="flex  justify-between gap-2">
        <a
          href={data.resume}
          target="_blank"
          rel="noopener noreferrer"
          className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-full mr-6 self-start cursor-pointer"
        >
          Resume
        </a>
        {data.linkedin ? (
          <a
            href={data.linkedin}
            target="_blank"
            rel="noopener noreferrer"
            className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-full self-start cursor-pointer"
          >
            LinkedIn
          </a>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default JobSeekerCard;
