import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { toast, Toaster } from "react-hot-toast";
import { getAllUsers } from "../../apis/user";
import JobSeekerCard from "./JobSeekerCard";
import DefaultLayout from "../../components/DefaultLayout";

function JobSeekers() {
  // fetch all job seekers
  const [profiles, setProfiles] = useState([]);

  // get users data
  useEffect(() => {
    (async () => {
      const dbResponse = await getAllUsers();
      if (dbResponse.success) {
        setProfiles(dbResponse.data);
      } else {
        // if not a member
        toast.error(dbResponse.message);
        window.location.href = "/dashboard";
      }
    })();
  }, []);
  // search job by tag functionality
  const [searchTerm, setSearchTerm] = useState("");

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredProfiles = profiles?.filter((item) =>
    item.tags.some((tag) => tag.includes(searchTerm))
  );

  return (
    <div>
      <DefaultLayout />
      <Toaster toastOptions={{ duration: 4000 }} />
      <div className="mx-5 my-10">
        <div className=" mx-auto mb-3">
          <label htmlFor="search" className="">
            Search
          </label>
          <input
            type="text"
            id="search"
            placeholder="Search by tag"
            value={searchTerm}
            onChange={handleChange}
            className="border border-gray-300 rounded-md py-2 px-4 w-full"
          />
        </div>

        <div className="space-y-4 ">
          {searchTerm === ""
            ? profiles
                ?.reverse()
                .map((profile, index) => (
                  <JobSeekerCard key={index} data={profile} />
                ))
            : filteredProfiles
                ?.reverse()
                .map((profile, index) => (
                  <JobSeekerCard key={index} data={profile} />
                ))}
        </div>
      </div>
    </div>
  );
}

export default JobSeekers;
