import React from "react";
import { toast } from "react-hot-toast";
import { updateMembership } from "../../apis/user";

function MemberForm({ onClose }) {
  const userType = localStorage.getItem("userType");
  const formDataHandler = async (e) => {
    try {
      e.preventDefault();
      const payload = {
        membershipDetails: {
          userName: e.target.username.value || "",
          orgName: e.target.orgName.value || "",
          designation: e.target.designation.value || "",
          linkedin: e.target.userLinkedin.value || "",
          createdOn: new Date()
        },
      };

      const response = await updateMembership(payload, userType);
      if (response.success) {
        toast.success(response.message);
        onClose();
      } else {
        toast.error(response.message);
        console.log(response.message);
      }
    } catch (error) {
      console.log(error.message);
      toast.error(error.message);
    }
  };
  return (
    <div className="bg-slate-400">
      <div className="bg-white rounded-lg shadow-lg p-8 relative">
        <button
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-800 focus:outline-none"
          onClick={onClose}
        >
          <svg viewBox="0 0 24 24" className="w-6 h-6">
            <path
              fill="currentColor"
              d="M19.06 5.84a1 1 0 0 0-1.41 0L12 10.59L7.36 5.94a1 1 0 1 0-1.41 1.41L10.59 12l-4.24 4.24a1 1 0 1 0 1.41 1.41L12 13.41l4.65 4.65a1 1 0 0 0 1.41-1.41L13.41 12l4.65-4.65a1 1 0 0 0 0-1.41z"
            />
          </svg>
        </button>
        <h2 className="text-2xl font-bold mb-4">Enter your Details</h2>
        <form onSubmit={formDataHandler}>
          <div className="mb-4">
            <label
              className="block text-gray-700 font-bold mb-2"
              htmlFor="username"
            >
              Your Name
            </label>
            <input
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="username"
              type="text"
              placeholder="Enter your name"
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 font-bold mb-2"
              htmlFor="orgName"
            >
              Your Organization Name
            </label>
            <input
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="orgName"
              type="text"
              placeholder="Enter your organization name"
            />
          </div>

          <div className="mb-4">
            <label
              className="block text-gray-700 font-bold mb-2"
              htmlFor="designation"
            >
              Your Designation
            </label>
            <input
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="designation"
              type="text"
              placeholder="Enter your designation in your organization"
            />
          </div>

          <div className="mb-4">
            <label
              className="block text-gray-700 font-bold mb-2"
              htmlFor="userLinkedin"
            >
              Your Linkedin link *
            </label>
            <input
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="userLinkedin"
              type="link"
              placeholder="Enter your linkedin link"
              required
            />
          </div>

          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mx-auto"
            type="submit"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
}

export default MemberForm;

