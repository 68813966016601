import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

function DefaultLayout() {
  const navigate = useNavigate();
  const userType = localStorage.getItem("userType");

  let userMenu = userType === "admin" && [
    {
      title: "Profile",
      onClick: () => navigate("/dashboard"),
      icon: <i class="ri-file-list-3-line"></i>,
      path: "/dashboard",
    },
    {
      title: "All Job-seekers",
      onClick: () => navigate("/job-seekers"),
      icon: <i class="ri-file-list-2-line"></i>,
      path: "/job-seekers",
    },
    {
      title: "All Recruiters",
      onClick: () => navigate("/recruiters"),
      icon: <i class="ri-file-list-2-line"></i>,
      path: "/recruiters",
    },
    {
      title: "All jobs",
      onClick: () => navigate(`/jobs`),
      icon: <i class="ri-user-2-line"></i>,
      path: "/jobs",
    },
    {
      title: "Logout",
      onClick: () => {
        window.localStorage.clear();
        navigate("/");
      },
      icon: <i class="ri-logout-box-r-line"></i>,
      path: "/",
    },
  ];
  if (userType === "job-seeker") {
    userMenu = [
      {
        title: "Profile",
        onClick: () => navigate("/dashboard"),
        icon: <i class="ri-file-list-3-line"></i>,
        path: "/dashboard",
      },
      {
        title: "All jobs",
        onClick: () => navigate(`/jobs`),
        icon: <i class="ri-user-2-line"></i>,
        path: "/jobs",
      },
      {
        title: "Logout",
        onClick: () => {
          window.localStorage.clear();
          navigate("/");
        },
        icon: <i class="ri-logout-box-r-line"></i>,
        path: "/",
      },
    ];
  } else if (userType === "job-giver") {
    userMenu = [
      {
        title: "Profile",
        onClick: () => navigate("/dashboard"),
        icon: <i class="ri-file-list-3-line"></i>,
        path: "/dashboard",
      },
      {
        title: "All Job-seekers",
        onClick: () => navigate("/job-seekers"),
        icon: <i class="ri-file-list-2-line"></i>,
        path: "/job-seekers",
      },
      {
        title: "Logout",
        onClick: () => {
          window.localStorage.clear();
          navigate("/");
        },
        icon: <i class="ri-logout-box-r-line"></i>,
        path: "/",
      },
    ];
  }
  const [isMobile, setIsMobile] = useState(false);

  const toggleMenu = () => {
    setIsMobile(!isMobile);
  };

  return (
    <nav className="bg-gray-800">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-24 sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
          <div className="flex-shrink-0 flex items-center ">
            <Link
              to="/"
              className=" border-transparent  text-gray-500 hover:text-gray-700 hover:border-gray-300 inline-flex items-center px-1 pt-1 border-b-2  font-medium gap-2"
            >
              <img src="logo.png" alt="Ritanya Sanshta Logo" className="h-16" />
            </Link>
          </div>

          <div className="md:hidden flex">
            {" "}
            <button
              type="button"
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
              onClick={toggleMenu}
            >
              <span className="sr-only">Open menu</span>
              {isMobile ? (
                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              ) : (
                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              )}
            </button>
          </div>
          <div className="hidden md:block ">
            {userMenu.map((menu, index) => {
              return (
                <button
                  key={index}
                  onClick={menu.onClick}
                  className="  text-gray-100 hover:text-gray-300  px-2   text-lg font-medium border-2 mr-4"
                >
                  {menu.title}
                </button>
              );
            })}
          </div>
        </div>
      </div>

      <div
        className={`${isMobile ? "block" : "hidden"} md:hidden inset-0 z-50 `}
      >
        <div className="px-2 pt-2 pb-3  space-y-5 sm:px-3 h-full flex flex-col justify-center items-center">
          {userMenu.map((menu, index) => {
            return (
              <button
                key={index}
                onClick={menu.onClick}
                className="  text-gray-100 hover:text-gray-300  px-2 py-1 text-lg font-medium border-2 mr-4"
              >
                {menu.title}
              </button>
            );
          })}
        </div>
      </div>
    </nav>
  );
}
export default DefaultLayout;
