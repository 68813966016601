import { FaPhone, FaEnvelope, FaFacebook, FaTwitter, FaInstagram } from 'react-icons/fa';

function Footer() {
  return (
    <footer className="bg-gray-800 py-3  w-full">
      <div className="container text-center mx-auto px-4 sm:px-6 lg:px-8 flex flex-col md:flex-row justify-between items-center">
        <div className="flex items-center mb-4 md:mb-0">
          <FaPhone className="text-white mr-2" />
          <span className="text-gray-400">+91 9873541100</span>
        </div>
        <div className="flex items-center mb-4 md:mb-0">
          <FaEnvelope className="text-white mr-2" />
          <a href="mailto:contact@example.com" className="text-gray-400 hover:text-white transition-colors duration-300">info@ritanyasanstha.org</a>
        </div>
        <div className="flex items-center mt-3 md:mt-0 space-x-8">
          <a href="https://www.facebook.com/ritanyasanstha/" target="_blank" rel="noopener noreferrer" className="text-gray-100 hover:text-white transition-colors duration-300 mx-2"><FaFacebook /></a>
          <a href="https://twitter.com/ritanyasanstha" target="_blank" rel="noopener noreferrer" className="text-gray-100 hover:text-white transition-colors duration-300 mx-2"><FaTwitter /></a>
          <a href="https://www.instagram.com/ritanyasanstha/" target="_blank" rel="noopener noreferrer" className="text-gray-100 hover:text-white transition-colors duration-300 mx-2"><FaInstagram /></a>
        </div>
        
      </div>
      <div className="text-gray-400 text-sm mt-4 md:mt-1 text-center">© 
        2023 Ritanya Sanstha. All rights reserved.</div>
    </footer>
  );
}

export default Footer;