import React, { useEffect } from "react";
import { useState } from "react";

import { getByID, getByRecruiterID } from "../../apis/user";
import MembershipStatus from "./MembershipStatus";
import JobGiverProfile from "./JobGiverProfile";

function JobGiverDashboard() {
  const [isMember, setIsMember] = useState("");
  const [profiles, setProfiles] = useState([]);
  const [membershipDetails, setMembershipDetails] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      let dbResponse;
      if (window.location.search) {
        const recruiterID = window.location.search.slice(4).trim();
        dbResponse = await getByRecruiterID(recruiterID);
      } else {
        dbResponse = await getByID();
      }
      if (dbResponse.success) {
        localStorage.setItem("userID", dbResponse.userID);
        dbResponse.data.membershipDetails &&
          setMembershipDetails(dbResponse.data.membershipDetails);

        setLoading(false);
        // check from db if user is a me  mber or not
        if (dbResponse.data.isMember) {
          setIsMember(true);
          dbResponse.data.profiles && setProfiles(dbResponse.data.profiles);
        }
      } else {
        setLoading(true);
      }
    })();
  }, []);
  return (
    <>
      {loading ? (
        <div>Loading...</div>
      ) : isMember ? (
        <JobGiverProfile
          profiles={profiles}
          name={membershipDetails?.userName || "Member"}
        />
      ) : (
        <MembershipStatus membershipDetails={membershipDetails} />
      )}
    </>
  );
}

export default JobGiverDashboard;
