import { addDoc, collection, getDocs, query, where } from "firebase/firestore";
import { fireDB } from "../firebase.config";

export const register = async (payload) => {
  const { phoneNumber, userType } = payload;
  let dbName;
  if (userType === "job-seeker") {
    dbName = "users";
  } else if (userType === "job-giver") {
    dbName = "job-giver";
  } else {
    dbName = "admin";
  }
  try {
    const qry = query(
      collection(fireDB, dbName),
      where("phoneNumber", "==", phoneNumber)
    );
    const querySnapshot = await getDocs(qry);
    let response;
    querySnapshot.forEach((doc) => {
      response = doc.id;
    });
    if (querySnapshot.size > 0) {
      return {
        success: true,
        message: "user logged in successfully",
        data: response,
      };
    }
    // if user does not exist
    // add user to db into users collection
    const docRef = await addDoc(collection(fireDB, dbName), payload);
    return {
      success: true,
      message: "user logged in successfully",
      data: docRef.id,
    };
  } catch (error) {
    console.log("error");
    return {
      success: false,
      message: error.message,
      data: null,
    };
  }
};
