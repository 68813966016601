import { Navigate } from "react-router-dom";

function ProtectedRoutes({ children }) {
  const userID = localStorage.getItem("userID");
  if (userID || window.location.search) {
    return children;
  } else {
    return <Navigate to="/" />;
  }
}

export default ProtectedRoutes;
