import React, { useState } from "react";
import ShowJobSeekerData from "./ShowJobSeekerData";
import { useNavigate } from "react-router-dom";
import JobSeekerForm from "./JobSeekerForm";
import { updateDocument } from "../../apis/user";
import { Toaster, toast } from "react-hot-toast";

function UserProfile({ profiles, name }) {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [apiData, setApiData] = useState(profiles || []);

  const handleFormSubmit = async (formData) => {
    setApiData((prevData) => [...prevData, formData]); // push formValues into apiData array
    try {
      const response = await updateDocument([...apiData, formData]);
      if (response.success) {
        toast.success(response.message);
      } else {
        toast.error(response.message);
        console.log(response.message);
      }
    } catch (error) {
      toast.error(error.message);
      console.log(error.message);
    }
  };

  const deleteprofileHandler = async (index) => {
    const newData = [...apiData];
    newData.splice(index, 1);
    setApiData(newData);
    try {
      updateDocument(newData);
    } catch (error) {
      toast.error(error.message);
      console.error("Error deleting job seeker:", error);
    }
  };
  // merge apiData and form data
  const combinedData = [...apiData];

  useState(() => {
    navigate("/dashboard");
  }, [isOpen]);

  return (
    <>
      <Toaster toastOptions={{ duration: 4000 }} />
      <div className={`${isOpen ? "blur-lg " : ""} max-w-7xl md:mx-auto mx-2`}>
        <h2 className="mb-2 ml-2 font-semibold">Welcome {name}</h2>
        <button
          className="ml-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-10"
          onClick={() => setIsOpen(true)}
        >
          Add Job seekers
        </button>
        {!isOpen && (
          <ShowJobSeekerData
            profiles={combinedData}
            onDelete={deleteprofileHandler}
          />
        )}
      </div>
      <JobSeekerForm
        isOpen={isOpen}
        onSubmit={handleFormSubmit}
        onClose={() => {
          setIsOpen(false);
        }}
      />
    </>
  );
}

export default UserProfile;
