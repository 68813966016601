import AdminDashboard from "../../components/Admin/AdminDashboard";
import DefaultLayout from "../../components/DefaultLayout";
import Footer from "../../components/Footer";
import JobGiverDashboard from "../../components/JobGiver/JobGiverDashboard";
import JobSeekerDashboard from "../../components/DashboardComponent/JobSeekerDashboard";

function Dashboard() {
  const userType = localStorage.getItem("userType");
  const showJobGiverDashboard =
    window.location.search || userType === "job-giver";
  return (
    <>
      <DefaultLayout />
      <div className="mx-5 md:mx-40 my-10">
        {showJobGiverDashboard && <JobGiverDashboard />}
        {userType === "job-seeker" && <JobSeekerDashboard />}
        {userType === "admin" && <AdminDashboard />}
      </div>
      <Footer />
    </>
  );
}

export default Dashboard;
