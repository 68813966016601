import React from "react";
import Card from "./Card";

function ShowJobSeekerData({ profiles, onDelete }) {
  return (
    <div className="space-y-8 ">
      {profiles &&
        profiles.map((profile, index) => (
          <Card
            key={index}
            index={index}
            data={profile}
            onDelete={() => onDelete(index)}
          />
        ))}
    </div>
  );
}

export default ShowJobSeekerData;
