import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { useState } from "react";
import { toast, Toaster } from "react-hot-toast";
import { storage } from "../../firebase.config";
import { FaInfoCircle } from "react-icons/fa";

function JobPostForm({ isOpen, onClose, onSubmit }) {
  const [tags, setTags] = useState([]);
  const [url, setUrl] = useState(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const toggleTooltip = () => setShowTooltip(!showTooltip);

  const handleFileInput = (event) => {
    const fileRef = ref(storage, "jobs");
    // upload ifles
    uploadBytes(fileRef, event.target.files[0])
      .then(() => {
        getDownloadURL(fileRef)
          .then((downloadUrl) => {
            setUrl(downloadUrl);
          })
          .catch((error) => {
            console.log(error.message);
            toast.error(error.message, "error getting the file Url");
          });
      })
      .catch((error) => {
        console.log(error.message);
        toast.error(error.message, "error uploading the file");
      });
  };

  const handleTagInput = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      const newTag = event.target.value.trim();
      if (
        newTag !== "" &&
        newTag.length < 30 &&
        !tags.includes(newTag) &&
        tags.length < 10
      ) {
        setTags((prev) => {
          const newTags = [...tags];
          return [...newTags, newTag];
        });
        event.target.value = "";
      }
    }
  };

  const handleRemoveTag = (tagToRemove) => {
    setTags(tags.filter((tag) => tag !== tagToRemove));
  };

  const formSubmitHandler = async (e) => {
    e.preventDefault();
    // create a new Date object
    const currentDate = new Date().toDateString();

    const payload = {
      title: e.target.title.value || "",
      jobLink: e.target.jobLink.value || "",
      jobDescription: e.target.description.value || "",
      tags: tags || [],
      attachement: url || "",
      company: e.target.company.value || "",
      recruiterName: e.target.recruiterName.value || "",
      recruiterEmail: e.target.recruiterEmail.value || "",
      recruiterPhoneNumber: e.target.recruiterPhoneNumber.value || "",
      postDate: currentDate,
    };
    onSubmit(payload);
    setTags([]);
    onClose();
  };
  return (
    <>
      <Toaster toastOptions={{ duration: 4000 }} />
      {/* Modal */}
      <div className="bg-slate-400  ">
        <div className="bg-white rounded-lg shadow-lg px-4 relative">
          <button
            className="absolute top-2 right-2 text-gray-500 hover:text-gray-800 focus:outline-none"
            onClick={onClose}
          >
            <svg viewBox="0 0 24 24" className="w-6 h-6">
              <path
                fill="currentColor"
                d="M19.06 5.84a1 1 0 0 0-1.41 0L12 10.59L7.36 5.94a1 1 0 1 0-1.41 1.41L10.59 12l-4.24 4.24a1 1 0 1 0 1.41 1.41L12 13.41l4.65 4.65a1 1 0 0 0 1.41-1.41L13.41 12l4.65-4.65a1 1 0 0 0 0-1.41z"
              />
            </svg>
          </button>
          <h2 className="text-2xl font-bold mb-4">Fill Job Post Details</h2>
          <form onSubmit={formSubmitHandler}>
            <label
              htmlFor="title"
              className="block mb-2 text-gray-700  font-bold"
            >
              Job title*:
            </label>
            <input
              type="text"
              id="title"
              name="title"
              className="w-full border border-gray-400 p-2  rounded-lg mb-4"
            />
            <label htmlFor="company" className="block mb-2 font-bold">
              Company Name*:
            </label>
            <input
              type="text"
              id="company"
              name="company"
              className="w-full border border-gray-400 p-2  rounded-lg mb-4"
            />
            <label htmlFor="description" className="block mb-2 font-bold">
              Job Description:
            </label>
            <textarea
              type="text"
              id="description"
              name="description"
              className="w-full border border-gray-400 p-2 rounded-lg mb-4"
            />

            <label htmlFor="jobLink" className="block mb-2 font-bold">
              Job Link:
            </label>
            <input
              type="url"
              id="jobLink"
              name="jobLink"
              className="w-full border border-gray-400 p-2 rounded-lg mb-4"
            />
            <label htmlFor="file" className="block mb-2 font-bold">
              Job Attachment(pdf/doc):
            </label>
            <input
              type="file"
              id="file"
              name="file"
              className="w-full border border-gray-400 p-2 rounded-lg mb-4"
              onChange={handleFileInput}
            />
            <label htmlFor="recruiterName" className="block mb-2 font-bold">
              Recruiter Name:
            </label>
            <input
              type="text"
              id="recruiterName"
              name="recruiterName"
              className="w-full border border-gray-400 p-2  rounded-lg mb-4"
            />
            <label htmlFor="recruiterEmail" className="block mb-2 font-bold">
              Recruiter Email:
            </label>
            <input
              type="email"
              id="recruiterEmail"
              name="recruiterEmail"
              className="w-full border border-gray-400 p-2  rounded-lg mb-4"
            />
            <label
              htmlFor="recruiterPhoneNumber"
              className="block mb-2 font-bold"
            >
              Recruiter PhoneNumber:
            </label>
            <input
              type="tel"
              id="recruiterPhoneNumber"
              // name="name"
              className="w-full border border-gray-400 p-2  rounded-lg mb-4"
            />

            <div className="mb-4">
              <div
                className="relative"
                onMouseEnter={toggleTooltip}
                onMouseLeave={toggleTooltip}
              >
                <div className="flex items-center">
                  <label htmlFor="tags" className="block font-bold mb-2">
                    Mention all relvant Tags/Keywords:
                  </label>
                  <FaInfoCircle className="text-gray-400" />
                </div>
                {showTooltip && (
                  <div
                    className="absolute bg-gray-800 text-white text-xs rounded-lg p-2  bottom-full left-1/2 transform -translate-x-1/2"
                    role="tooltip"
                  >
                    Add upto 10 job post relevant tags
                  </div>
                )}
              </div>

              <input
                type="text"
                id="tags"
                // name="tags"
                placeholder="Add tags (press enter to add)"
                className="border border-gray-400 p-2 rounded-lg mb-2 w-full"
                onKeyDown={handleTagInput}
              />
              <div className="flex flex-wrap items-center">
                {tags.map((tag) => (
                  <div
                    key={tag}
                    className="bg-gray-200 text-gray-800 px-2 flex justify-center items-center text-xs rounded-full mr-2  mb-2 max-w-full overflow-hidden"
                  >
                    {tag}
                    <button
                      type="button"
                      onClick={() => handleRemoveTag(tag)}
                      className="ml-2 text-sm font-medium text-gray-500 focus:outline-none hover:text-gray-700"
                    >
                      &times;
                    </button>
                  </div>
                ))}
              </div>
            </div>

            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mb-2  rounded"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </>
  );
}

export default JobPostForm;
