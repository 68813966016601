import { Navigate } from "react-router-dom";

function PrivateRoute({ children }) {
  const userID = localStorage.getItem("userID");
  if (userID) {
    return <Navigate to="/dashboard" />;
  } else {
    return children;
  }
}

export default PrivateRoute;
