import React from "react";

function Card({ data, id, isMember, onDelete, phoneNumber, verifyUser }) {
  return (
    <div className="bg-white rounded-lg shadow-md flex flex-col md:flex-row gap-5 justify-between items-center py-2 px-2 ">
      <div>
        {data?.userName && (
          <div className="font-bold">{data?.userName || ""}</div>
        )}
        <div className="text-center">{phoneNumber || ""}</div>
      </div>
      <div className="flex gap-4">
        {data?.degree ||
          (data?.gradYear && (
            <div>
              {data?.degree || ""} <span>{data?.gradYear || ""}</span>
            </div>
          ))}
        {data?.college && <div>{data?.college || ""} </div>}
      </div>

      {data?.linkedin ? (
        <a
          href={data?.linkedin}
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-500 hover:underline"
        >
          LinkedIn
        </a>
      ) : (
        ""
      )}
      <div className="flex gap-4">
        {!isMember && (
          <button
            className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-full self-start cursor-pointer"
            onClick={(e) => {
              verifyUser(e, id);
            }}
          >
            Verify
          </button>
        )}
        {!isMember && (
          <button
            className="bg-red-500 hover:bg-red-700 text-white py-2 px-4 rounded-full self-start cursor-pointer"
            onClick={onDelete}
          >
            Delete
          </button>
        )}
      </div>
    </div>
  );
}

export default Card;
