import React from "react";
import DefaultLayout from "../components/DefaultLayout";
import Footer from "../components/Footer";

function Recruiters() {
  return (
    <>
      <DefaultLayout />

      <Footer />
    </>
  );
}

export default Recruiters;
