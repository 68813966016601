import HomePage from "./pages/user/HomePage";
import Dashboard from "./pages/user/Dashboard";
import Login from "./pages/user/Login";
import { Route, Navigate, Routes } from "react-router-dom";
import ProtectedRoutes from "./components/ProtectedRoutes";
import Jobs from "./pages/user/Jobs";
import JobSeekers from "./pages/user/JobSeekers";
import JobSeekerForm from "./components/DashboardComponent/JobSeekerForm";
import { useState } from "react";
import { Context } from "./Context";
import JobPostForm from "./components/JobGiver/JobPostForm";
import MemberForm from "./components/DashboardComponent/MemberForm";
import PrivateRoute from "./components/PrivateRoute";
import Recruiters from "./pages/Recruiters";

function App() {
  const [userType, setUserType] = useState("");

  return (
    <Context.Provider value={{ userType, setUserType }}>
      <div className="min-h-[100vh] flex flex-col  justify-between">
        <Routes>
          <Route
            path="/"
            element={
              <PrivateRoute>
                <HomePage />
              </PrivateRoute>
            }
          />
          <Route
            path="/login"
            element={
              <PrivateRoute>
                <Login />
              </PrivateRoute>
            }
          />
          <Route
            path="/recruiters"
            element={
              <ProtectedRoutes>
                <Recruiters />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoutes>
                <Dashboard />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/jobs"
            element={
              <ProtectedRoutes>
                <Jobs />
              </ProtectedRoutes>
            }
          />

          <Route
            path="/profile-form"
            element={
              <ProtectedRoutes>
                <JobSeekerForm />
              </ProtectedRoutes>
            }
          />

          <Route
            path="/job-seekers"
            element={
              <ProtectedRoutes>
                <JobSeekers />
              </ProtectedRoutes>
            }
          />

          <Route
            path="/job-form"
            element={
              <ProtectedRoutes>
                <JobPostForm />
              </ProtectedRoutes>
            }
          />

          <Route
            path="/membership-form"
            element={
              <ProtectedRoutes>
                <MemberForm />
              </ProtectedRoutes>
            }
          />

          <Route path="*" element={<Navigate to="/dashboard" replace />} />
        </Routes>
      </div>
    </Context.Provider>
  );
}

export default App;
