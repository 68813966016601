import React from "react";
import { useState } from "react";

const JobCard = ({ data, onDelete }) => {
  const {
    company,
    attachement,
    title,
    jobLink,
    jobDescription,
    recruiterName,
    recruiterEmail,
    tags,
    recruiterPhone,
    postDate,
  } = data;

  const [expanded, setExpanded] = useState(false);
  const MAX_HEIGHT = 150; // maximum height of job description
  const userType = localStorage.getItem("userType");

  const toggleExpansion = () => {
    setExpanded(!expanded);
  };
  return (
    <div className="p-4 border border-gray-300 rounded-lg shadow-md flex  flex-col space-y-3 relative ">
      {userType !== "job-seeker" && (
        <button
          className="text-sm absolute top-0 right-0 text-gray-500 hover:text-gray-800 focus:outline-none  p-1 bg-red-500 hover:bg-red-700"
          onClick={onDelete}
        >
          Delete
        </button>
      )}
      <div className="flex flex-col md:flex-row justify-between">
        {/* tile and company  */}
        <div className="">
          <h2 className="text-lg md:text-xl font-medium ">{title}</h2>
          <p className="text-gray-700 text-md">{company}</p>
          <span className="text-gray-700 text-xs italic mb-2">
            posted on - {postDate}
          </span>
        </div>
        {/* links and desctiption */}
        <div>
          {attachement && (
            <p className="text-gray-700 mb-2">
              <a
                href={attachement}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 hover:underline"
              >
                Job attachment
              </a>
            </p>
          )}
          {jobLink && (
            <p className="text-gray-700 mb-2">
              <a
                href={jobLink}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 hover:underline"
              >
                Job Link
              </a>
            </p>
          )}
        </div>
      </div>
      {/* tags */}
      <div className="flex flex-wrap mb-2 ">
        {tags.map((tag) => (
          <span
            key={tag}
            className="bg-gray-200 text-gray-800 rounded-full py-1 px-3 text-xs font-semibold mr-2 mb-2 inline-block"
          >
            {tag}
          </span>
        ))}
      </div>

      {/* recruiter  */}
      <div className="flex flex-col md:flex-row  items-center">
        {/* <div> */}
        {(recruiterEmail || recruiterName || recruiterPhone) && (
          <h3 className="text-lg font-medium ">Recruiter:</h3>
        )}
        <p className="text-gray-700 mx-2">{recruiterName},</p>
        <p className="text-gray-700 mx-2">{recruiterEmail}</p>
        <p className="text-gray-700 mx-2">{recruiterPhone}</p>
        {/* </div> */}
      </div>

      {/* description  */}
      <div className="">
        <p className="text-sm font-medium text-gray-500">
          {jobDescription.substring(0, MAX_HEIGHT)}
          {!expanded && jobDescription.length > MAX_HEIGHT && (
            <>
              ...
              <span
                className="text-blue-600 hover:underline cursor-pointer"
                onClick={toggleExpansion}
              >
                Read More
              </span>
            </>
          )}
          {expanded && jobDescription.length > MAX_HEIGHT && (
            <>
              {jobDescription.substring(MAX_HEIGHT)}
              <span
                className="text-blue-600 hover:underline cursor-pointer"
                onClick={toggleExpansion}
              >
                Read Less
              </span>
            </>
          )}
        </p>
      </div>
    </div>
  );
};

export default JobCard;
