import { updateMembership } from "../../apis/user";
import { toast, Toaster } from "react-hot-toast";
const arrayRange = (start, stop, step) =>
  Array.from(
    { length: (stop - start) / step + 1 },
    (value, index) => start + index * step
  );
let range = arrayRange(1950, 2030, 1);

function MemberForm({ onSubmitForm }) {
  const userType = localStorage.getItem("userType");

  const updateUserDataHandler = async (e) => {
    try {
      e.preventDefault();
      const payload = {
        membershipDetails: {
          userName: e.target.username.value || "",
          linkedin: e.target.userLinkedin.value || "",
          college: e.target.college.value || "",
          degree: e.target.degree.value || "",
          gradYear: e.target.gradYear.value || "",
        },
      };

      const response = await updateMembership(payload, userType);
      if (response.success) {
        toast.success(response.message);
        onSubmitForm(false);
      } else {
        toast.error(response.message);
        onSubmitForm(true);
      }
    } catch (error) {
      console.log(error.message);
      toast.error(error.message);
      onSubmitForm(true);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center">
      <h2 className="text-xl font-bold mb-4">Member Details</h2>
      <p>
        Please note that these details are for verification of member who is
        creating account.{" "}
      </p>
      <Toaster toastOptions={{ duration: 4000 }} />
      <form
        className="max-w-screen-lg md:w-[600px] mx-auto py-4 px-8 mt-8 border-2"
        onSubmit={updateUserDataHandler}
      >
        <div className="mb-4">
          <label
            className="block text-gray-700 font-bold mb-2"
            htmlFor="username"
          >
            Your Name
          </label>
          <input
            className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="username"
            type="text"
            placeholder="Enter your name"
          />
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700 font-bold mb-2"
            htmlFor="college"
          >
            Your College/University
          </label>
          <input
            className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="college"
            type="text"
            placeholder="Enter your college/university Name"
          />
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700 font-bold mb-2"
            htmlFor="degree"
          >
            Your Degree
          </label>
          <select
            id="degree"
            name="degree"
            className="mt-1 py-2 block w-full rounded-md bg-gray-100 border-transparent focus:bg-white focus:border-gray-500 focus:ring-0"
          >
            <option value="">-- Select your degree --</option>
            {["Graduation", "Post Graduation", "Doctorate"].map(
              (degree, index) => (
                <option key={index} value={degree}>
                  {degree}
                </option>
              )
            )}
          </select>
        </div>

        <div className="mb-4">
          <label
            className="block text-gray-700 font-bold mb-2"
            htmlFor="gradYear"
          >
            Year of Graduation
          </label>
          <select
            id="gradYear"
            name="gradYear"
            className="mt-1 py-2 block w-full rounded-md bg-gray-100 border-transparent focus:bg-white focus:border-gray-500 focus:ring-0"
          >
            <option value="">-- Select Year --</option>
            {range.map((year, index) => (
              <option key={index} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>
        {/* linkedin  */}
        <div className="mb-4">
          <label
            className="block text-gray-700 font-bold mb-2"
            htmlFor="duserLinkedin"
          >
            Your Linkedin link*
          </label>
          <input
            className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="userLinkedin"
            type="link"
            placeholder="Enter your linkedin link"
            required
          />
        </div>

        { /** ID card */}
        {/* <div className="mb-4">
          <label htmlFor="userID" className="block text-gray-700 font-bold mb-2">Upload official ID Card* (if linkedin not available)</label>
          <input type="file" name="userID" id="userID" onChange={handleFileInput} />
        </div> */}

        <div className="mb-4">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="submit"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}

export default MemberForm;
