import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { getByID } from "../../apis/user";
import MembershipStatus from "./MembershipStatus";
import UserProfile from "./UserProfile";

function JobSeekerDashboard() {
  const [isMember, setIsMember] = useState(false);
  const [profiles, setProfiles] = useState([]);
  const [membershipDetails, setMembershipDetails] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // fetch user's data from BE
    (async () => {
      const dbResponse = await getByID();
      if (dbResponse.success) {
        dbResponse.data.membershipDetails &&
          setMembershipDetails(dbResponse.data.membershipDetails);
        setLoading(false);

        // check from db if user is a member or not
        if (dbResponse.data.isMember) {
          setIsMember(true);
          setProfiles(dbResponse.data.profiles);
        }
      } else {
        setLoading(true);
      }
    })();
  }, []);

  return (
    <>
      {loading ? (
        <div>Loading...</div>
      ) : isMember ? (
        <UserProfile profiles={profiles} name={membershipDetails.userName || ''} />
      ) : (
        <MembershipStatus membershipDetails={membershipDetails} />
      )}
    </>
  );
}

export default JobSeekerDashboard;
