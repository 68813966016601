import React, { useEffect } from "react";
import { useState } from "react";
import { Toaster } from "react-hot-toast";
import { FaUserTimes } from "react-icons/fa";
import MemberForm from "./MemberForm";

function MembershipStatus({ membershipDetails }) {
  console.log({ membershipDetails });
  const [showForm, setShowForm] = useState(false);
  const [isFormFilled, setIsFormFilled] = useState(false);
  useEffect(() => {
    if (membershipDetails) {
      setIsFormFilled(true);
    }
  }, [membershipDetails]);

  return (
    <>
      <Toaster toastOptions={{ duration: 4000 }} />
      <div className="max-w-5xl flex flex-col items-center justify-center border-2 py-4 border-red-500 px-2 shadow-md flex-wrap mx-auto mb-10">
        <FaUserTimes className="text-4xl" />
        <h2 className="text-lg mt-3 text-center">
          Your profile has been created and has been sent to platform admin for
          verification. You need to fill this form in order to provide details
          for verification.
        </h2>
        <p className="text-lg mt-3 text-center ">
          It might take upto 24 hours for completing the verification
          process.Please try logging in after 24 hours.
        </p>
      </div>

      {!isFormFilled ? (
        <div className="text-lg mt-3 text-center ">
          <h2 className="text-center text-lg underline mb-3">
            What can I do while I wait?
          </h2>
          <p className="text-md">
            Start filling in the rest of your profile, it will improve your
            chances of getting verified.
          </p>
        </div>
      ) : (
        <p>
          You have already submitted your details. Please submit it again if you
          wish to modify details.
        </p>
      )}
      <button
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mt-4 mx-auto text-center block"
        type="submit"
        onClick={() => {
          setShowForm(!showForm);
        }}
      >
        ReSubmit/Sumbit your profile data
      </button>
      {showForm && (
        <MemberForm
          onSubmitForm={(value) => {
            setShowForm(value);
            setIsFormFilled(!value);
          }}
        />
      )}
    </>
  );
}

export default MembershipStatus;
