import React from "react";
import Card from "./Card";

function ShowJobGiverData({ profiles, onDelete }) {
  return (
    <div className="space-y-4">
      {profiles &&
        profiles.map((job, index) => (
          <Card key={index} data={job} onDelete={() => onDelete(index)} />
        ))}
    </div>
  );
}

export default ShowJobGiverData;
