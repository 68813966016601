import "firebase/storage";
import {
  updateDoc,
  doc,
  getDoc,
  getDocs,
  collection,
  arrayUnion,
  query,
  where,
} from "firebase/firestore";
import { fireDB } from "../firebase.config";

// import {
//   SetReadNotifications,
//   SetUnreadNotifications,
// } from "../redux/notifications";
// import store from "../redux/store";

// const logout = () => {
//   signOut(auth);
// };

export const updateMembership = async (payload, userType) => {
  const userID = localStorage.getItem("userID");
  let dbName;
  if (userType === "job-seeker") {
    dbName = "users";
  } else if (userType === "job-giver") {
    dbName = "job-giver";
  } else {
    dbName = "admin";
  }

  try {
    await updateDoc(doc(fireDB, dbName, userID), payload);
    return {
      success: true,
      message: "data submitted successfully",
    };
  } catch (error) {
    console.log("error");
    return {
      success: false,
      message: "Something went wrong in updating data",
    };
  }
};

export const updateUserMembership = async (payload, id) => {
  // const userID = localStorage.getItem("userID");
  console.log(payload, id, "updateusermebersip");
  try {
    const docRef = doc(fireDB, "users", id);
    console.log(docRef);
    await updateDoc(docRef, { isMember: true });
    // docRef.set({ isMember: true }, { merge: true });

    return {
      success: true,
      message: "data submitted successfully",
    };
  } catch (error) {
    console.log("error");
    return {
      success: false,
      message: "Something went wrong in updating data",
    };
  }
};
// // const userID = localStorage.getItem("userID");
// export const updateJobGiverMembership = async (payload, id = userID) => {
//   try {
//     await updateDoc(doc(fireDB, "job-giver", id), payload);
//     return {
//       success: true,
//       message: "data submitted successfully",
//     };
//   } catch (error) {
//     return {
//       success: false,
//       message: "Something went wrong in updating data of job giver",
//     };
//   }
// };

export const updateDocument = async (payload) => {
  const userID = localStorage.getItem("userID");
  const userType = localStorage.getItem("userType");
  let dbName;
  if (userType === "job-seeker") {
    dbName = "users";
  } else if (userType === "job-giver") {
    dbName = "job-giver";
  } else {
    dbName = "admin";
  }
  try {
    await updateDoc(doc(fireDB, dbName, userID), {
      profiles: payload,
    });

    return {
      success: true,
      message: "data submitted successfully",
    };
  } catch (error) {
    console.log("error");
    return {
      success: false,
      message: "Something went wrong in updating data",
    };
  }
};
export const updateUserProfiles = async (payload) => {
  const userID = localStorage.getItem("userID");

  try {
    await updateDoc(doc(fireDB, "users", userID), {
      profiles: arrayUnion(payload),
    });

    return {
      success: true,
      message: "data submitted successfully",
    };
  } catch (error) {
    console.log("error");
    return {
      success: false,
      message: "Something went wrong in updating data",
    };
  }
};

export const updateJobGiverProfiles = async (payload) => {
  const userID = localStorage.getItem("userID");

  try {
    await updateDoc(doc(fireDB, "job-giver", userID), {
      profiles: arrayUnion(payload),
    });
    return {
      success: true,
      message: "data submitted successfully",
    };
  } catch (error) {
    console.log("error");
    return {
      success: false,
      message: "Something went wrong in updating data",
    };
  }
};

export const getJobByID = async () => {
  const userID = localStorage.getItem("userID");
  try {
    const docRef = doc(fireDB, "job-giver", userID);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return {
        success: true,
        message: "data retrieved successfully",
        data: docSnap.data(),
      };
    } else {
      return {
        success: false,
        message: "not able to find data",
      };
    }
  } catch (error) {
    console.log("error");
    return {
      success: false,
      message: "Something went wrongin fetching data",
    };
  }
};
export const getAdminByID = async () => {
  const userID = localStorage.getItem("userID");
  try {
    const docRef = doc(fireDB, "admin", userID);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return {
        success: true,
        message: "data submitted successfully",
        data: docSnap.data(),
      };
    } else {
      return {
        success: false,
        message: "Not found",
      };
    }
  } catch (error) {
    console.log("error");
    return {
      success: false,
      message: "Something went wrongin fetching job by ID",
    };
  }
};

export const getByID = async () => {
  const userID = localStorage.getItem("userID");
  const userType = localStorage.getItem("userType");

  let dbName;
  if (userType === "job-seeker") {
    dbName = "users";
  } else if (userType === "job-giver") {
    dbName = "job-giver";
  } else {
    dbName = "admin";
  }
  try {
    const docRef = doc(fireDB, dbName, userID);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return {
        success: true,
        message: "data retrieved successfully",
        data: docSnap.data(),
      };
    } else {
      return {
        success: false,
        message: "not able to find data",
      };
    }
  } catch (error) {
    console.log("error");
    return {
      success: false,
      message: "Something went wrongin fetching data",
    };
  }
};

/**
 * @description - this function will query the db based upon a field name. If it matches, it will
 * return the data associated else will return the errror.
 * @param {String} recruiterID - id of the recuiter taken from the URL query params
 * @returns
 */
export const getByRecruiterID = async (recruiterID) => {
  const dbName = "job-giver";
  try {
    // Create a reference to the cities collection
    const jobGiverCollectionRef = collection(fireDB, dbName);
    // do a query on this collection
    const queryOutput = query(
      jobGiverCollectionRef,
      where("recruiterID", "==", recruiterID)
    );
    const querySnapshot = await getDocs(queryOutput);
    if (querySnapshot.size) {
      let userID;
      let data;
      querySnapshot.forEach((doc) => {
        userID = doc.id;
        data = doc.data();
      });
      return {
        success: true,
        message: "data retrieved successfully",
        data,
        userID,
      };
    } else {
      return {
        success: false,
        message: "not able to find data",
      };
    }
  } catch (error) {
    console.log("error");
    return {
      success: false,
      message: "Something went wrong in fetching data",
    };
  }
};

export const getAllUsers = async () => {
  try {
    const users = [];
    const querySnapshot = await getDocs(collection(fireDB, "users"));
    querySnapshot.forEach((doc) => {
      if (doc.data().profiles) {
        users.push(...doc.data().profiles);
      }
    });
    return {
      success: true,
      data: users,
    };
  } catch (error) {
    console.log("error");
    return {
      success: false,
      message: "Something went wrong while fetching all data",
    };
  }
};

export const getAllJobs = async () => {
  try {
    const users = [];
    const querySnapshot = await getDocs(collection(fireDB, "job-giver"));

    querySnapshot.forEach((doc) => {
      if (doc.data().profiles) {
        users.push(...doc.data().profiles);
      }
    });

    return {
      success: true,
      data: users,
    };
  } catch (error) {
    console.log("error");
    return {
      success: false,
      message: "Something went wrong while fetching all jobs",
    };
  }
};
export const getAllVerifiedSeekers = async () => {
  try {
    const users = [];
    const querySnapshot = await getDocs(collection(fireDB, "users"));

    querySnapshot.forEach((doc) => {
      if (doc.data().isMember) {
        users.push({
          id: doc.id,
          isMember: true,
          membershipDetails: doc.data().membershipDetails,
          phoneNumber: doc.data().phoneNumber,
        });
      }
    });
    return {
      success: true,
      data: users,
    };
  } catch (error) {
    console.log("error");
    return {
      success: false,
      message: "Something went wrong while fetching all data",
    };
  }
};
export const getAllSeekersMembershipDetails = async () => {
  try {
    const users = [];
    const querySnapshot = await getDocs(collection(fireDB, "users"));

    querySnapshot.forEach((doc) => {
      if (!doc.data().isMember) {
        users.push({
          id: doc.id,
          membershipDetails: doc.data().membershipDetails || {},
          phoneNumber: doc.data().phoneNumber,
        });
      }
    });

    return {
      success: true,
      data: users,
    };
  } catch (error) {
    console.log("error");
    return {
      success: false,
      message: "Something went wrong while fetching all data",
    };
  }
};
export const getAllGiversMembershipDetails = async () => {
  try {
    const users = [];
    const querySnapshot = await getDocs(collection(fireDB, "job-giver"));
    querySnapshot.forEach((doc) => {
      console.log("each member details", doc.data());
      if (!doc.data().isMember) {
        users.push({
          id: doc.id,
          membershipDetails: doc.data().membershipDetails || {},
          phoneNumber: doc.data().phoneNumber,
        });
      }
    });

    return {
      success: true,
      data: users,
    };
  } catch (error) {
    console.log("error");
    return {
      success: false,
      message: "Something went wrong while fetching all data",
    };
  }
};

// export const getUserNofications = async () => {
//   const user = JSON.parse(localStorage.getItem("user"));
//   try {
//     const q = query(collection(fireDB, "users", user.id, "notifications"));
//     onSnapshot(q, (querySnapshot) => {
//       const notifications = [];
//       querySnapshot.forEach((doc) => {
//         notifications.push({ id: doc.id, ...doc.data() });
//       });

//       const readNotifications = notifications.filter(
//         (notification) => notification.status === "read"
//       );
//       const unreadNotifications = notifications.filter(
//         (notification) => notification.status === "unread"
//       );
//       store.dispatch(SetReadNotifications(readNotifications));
//       store.dispatch(SetUnreadNotifications(unreadNotifications));
//     });

//     return {
//       success: true,
//     };
//   } catch (error) {
//     return {
//       success: false,
//       message: "Something went wrong",
//     };
//   }
// };

// export const changeNotificationStatus = async (id, status) => {
//   const user = JSON.parse(localStorage.getItem("user"));
//   try {
//     await updateDoc(doc(fireDB, "users", user.id, "notifications", id), {
//       status,
//     });
//     return {
//       success: true,
//       message: "Notification status changed",
//     };
//   } catch (error) {
//     return {
//       success: false,
//       message: "Something went wrong",
//     };
//   }
// };
