import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import React from "react";
import { useState } from "react";
import { toast, Toaster } from "react-hot-toast";
import { storage } from "../../firebase.config";
import { FaInfoCircle } from "react-icons/fa";

function JobSeekerForm({ isOpen, onClose, onSubmit }) {
  const [tags, setTags] = useState([]);
  const [url, setUrl] = useState(null);
  const [showTooltip, setShowTooltip] = useState(false);

  const toggleTooltip = () => setShowTooltip(!showTooltip);

  const handleFileInput = (event) => {
    const fileRef = ref(storage, "resumes");
    // upload ifles
    uploadBytes(fileRef, event.target.files[0])
      .then(() => {
        getDownloadURL(fileRef)
          .then((downloadUrl) => {
            setUrl(downloadUrl);
          })
          .catch((error) => {
            console.log("error");
            toast.error(error.message, "error getting the file Url");
          });
      })
      .catch((error) => {
        console.log("error");
        toast.error(error.message, "error uploading the file");
      });
  };

  const handleTagInput = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      const newTag = event.target.value.trim();
      if (newTag !== "" && newTag.length < 30 && !tags.includes(newTag) && tags.length < 10) {
        setTags((prev) => {
          const newTags = [...tags];
          return [...newTags, newTag];
        });
        event.target.value = "";
      }
    }
  };

  const handleRemoveTag = (tagToRemove) => {
    setTags(tags.filter((tag) => tag !== tagToRemove));
  };

  const formSubmitHandler = async (e) => {
    e.preventDefault();
    const payload = {
      name: e.target.name.value || "",
      linkedin: e.target.linkedin.value || "",
      tags: tags || [],
      resume: url,
    };
    onSubmit(payload);
    setTags([]);
    onClose();
  };
  return (
    <>
      <Toaster toastOptions={{ duration: 4000 }} />
      {/* Modal */}

      {isOpen && (
        <div className="fixed z-10 inset-0 overflow-y-auto  ">
          <div className="flex items-center justify-center  shadow-md rounded  max-w-xl mx-auto overflow-x-scroll min-h-screen">
            <div className="bg-white rounded-lg p-8 relative">
              <span
                className="absolute top-0 right-0 text-gray-600 cursor-pointer border-2 px-2 py-1 border-black"
                onClick={onClose}
              >
                &times;
              </span>
              <h2 className="text-2xl font-bold mb-4">
                Fill Job Seeker Details
              </h2>
              <form
                className=" mx-auto mt-8 border-2 px-8 py-4"
                onSubmit={formSubmitHandler}
              >
                <label htmlFor="name" className="block mb-2 font-bold">
                  Name*:
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="your full name"
                  className="w-full border border-gray-400 p-2  rounded-lg mb-4"
                  required
                />

                <label htmlFor="linkedin" className="block mb-2 font-bold">
                  Linkedin profile Link*:
                </label>
                <input
                  type="url"
                  id="linkedin"
                  name="linkedin"
                  placeholder="your linkedin profile link"
                  className="w-full border border-gray-400 p-2 rounded-lg mb-4"
                />
                <label htmlFor="file" className="block mb-2 font-bold">
                  Upload resume in pdf format*:
                </label>
                <input
                  type="file"
                  id="file"
                  name="resume"
                  accept=".pdf"
                  className="w-full border border-gray-400 p-2 rounded-lg mb-4"
                  onChange={handleFileInput}
                  required
                />

                <div className="mb-4 ">
                  <div
                    className="relative"
                    onMouseEnter={toggleTooltip}
                    onMouseLeave={toggleTooltip}
                  >
                    <div className="flex items-center">
                      <label htmlFor="tags" className="block font-bold mb-2">
                        Mention all relvant Tags/Keywords:
                      </label>
                      <FaInfoCircle className="text-gray-400" />
                    </div>
                    {showTooltip && (
                      <div
                        className="absolute bg-gray-800 text-white text-xs rounded-lg p-2  bottom-full left-1/2 transform -translate-x-1/2"
                        role="tooltip"
                      >
                        Add upto 10 relevant tags to make your profile
                        searchable to recruiter.
                      </div>
                    )}
                  </div>

                  <input
                    type="text"
                    id="tags"
                    name="tags"
                    placeholder="Add tags (press enter to add)"
                    className="border border-gray-400 p-2 rounded-lg mb-2 w-full"
                    onKeyDown={handleTagInput}
                  />
                  <div className="flex flex-wrap items-center">
                    {tags.map((tag) => (
                      <div
                        key={tag}
                        className="bg-gray-200 text-gray-800 px-2 flex justify-center items-center rounded-full pb-1 mr-2  mb-2 max-w-full overflow-hidden text-xs"
                      >
                        {tag}
                        <button
                          type="button"
                          onClick={() => handleRemoveTag(tag)}
                          className="ml-2 text-sm font-medium text-gray-500 focus:outline-none hover:text-gray-700"
                        >
                          &times;
                        </button>
                      </div>
                    ))}
                  </div>
                </div>

                <button
                  type="submit"
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default JobSeekerForm;
