import React, { useEffect } from "react";
import { BsFillShieldLockFill, BsTelephoneFill } from "react-icons/bs";

import { FaSpinner } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import OTPInput from "otp-input-react";
import { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { auth } from "../../firebase.config";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { toast, Toaster } from "react-hot-toast";
import { register } from "../../apis/registration";

const Login = () => {
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const [showOTP, setShowOTP] = useState(false);
  const captchaRef = React.useRef(null);

  useEffect(() => {
    // to check if role is defined before use log in
    const userType = localStorage.getItem("userType");
    if (!userType) {
      window.location.href = "/";
    }
  });
  // function onCaptchaVerify() {
  //   try {
  //     if (!window.recaptchaVerifier) {
  //       window.recaptchaVerifier = new RecaptchaVerifier(
  //         captchaRef.current,
  //         {
  //           size: "invisible",
  //           callback: (response) => {
  //             onSendOTP();
  //           },
  //           "expired-callback": () => {},
  //         },
  //         auth
  //       );
  //     }
  //   } catch (error) {
  //     console.error("Error in onCaptchaVerify:", error);
  //   }
  // }

  async function onSendOTP() {
    try {
      setLoading(true);

      if (!window.recaptchaVerifier) {
        window.recaptchaVerifier = new RecaptchaVerifier(
          "recaptcha-container",
          { size: "invisible" },
          auth
        );
      }

      const appVerifier = window.recaptchaVerifier;
      const formattedPhoneNumber = "+" + phoneNumber;
      const confirmationResult = await signInWithPhoneNumber(
        auth,
        formattedPhoneNumber,
        appVerifier
      );
      window.confirmationResult = confirmationResult;
      setLoading(false);
      setShowOTP(true);
      toast.success("OTP sent successfully!");
    } catch (error) {
      // Error; SMS not sent
      console.log(error);
      setLoading(false);
      // reset the recaptcha
    }
  }

  async function onOTPVerifyandLogin() {
    try {
      const result = await window.confirmationResult.confirm(otp);
      // add data in db
      const userType = localStorage.getItem("userType");
      const lastLoginDate = new Date().toDateString();
      const dbResponse = await register({
        phoneNumber: result.user.phoneNumber,
        lastLoginDate,
        userType,
      });
      if (dbResponse.success) {
        toast.success("successfully logged in");
        //set user login info in local storage
        localStorage.setItem("userID", dbResponse.data);
        setLoading(true);
        // redirect to dashboard
        setTimeout(() => {
          navigate("/dashboard");
        }, 500);
      } else {
        setLoading(false);
        console.log(dbResponse.message);
        toast.error(dbResponse.message);
      }
    } catch (error) {
      setLoading(false);
      console.log(error.message);
      toast.error(error.message);
    }
  }

  const handleKeyDown = (event) => {
    console.log(event.keyCode, event.key);
    if (event.keyCode === 13) {
      onOTPVerifyandLogin();
    }
  };

  return (
    <>
      <section className="bg-emerald-500 flex items-center justify-center h-screen">
        <div className="w-full max-w-md">
          <Toaster toastOptions={{ duration: 4000 }} />
          <div className="w-full flex flex-col gap-4 rounded-lg p-4">
            <h1 className="text-center leading-normal text-white font-medium text-3xl mb-6">
              Ritanya Sanstha Job Portal
            </h1>
            {showOTP ? (
              <>
                <div className="bg-white text-emerald-500 w-fit mx-auto p-4 rounded-full">
                  <BsFillShieldLockFill size={30} />
                </div>
                <label
                  htmlFor="otp"
                  className="font-bold text-xl text-white text-center"
                >
                  Enter your OTP
                </label>
                <OTPInput
                  value={otp}
                  onChange={setOtp}
                  OTPLength={6}
                  otpType="number"
                  disabled={false}
                  autoFocus
                  className="opt-container justify-center"
                  onKeyDown={(e) => handleKeyDown(e)}
                ></OTPInput>
                <button
                  onClick={onOTPVerifyandLogin}
                  className="bg-emerald-600 w-full flex gap-1 items-center justify-center py-2.5 text-white rounded"
                >
                  {loading && (
                    <FaSpinner size={20} className="mt-1 animate-spin" />
                  )}
                  <span>Enter OTP & Login</span>
                </button>
              </>
            ) : (
              <>
                <div className="bg-white text-emerald-500 w-fit mx-auto p-4 rounded-full">
                  <BsTelephoneFill size={30} />
                </div>
                <label
                  htmlFor=""
                  className="font-bold text-xl text-white text-center"
                >
                  Verify your phone number
                </label>
                <PhoneInput
                  country={"in"}
                  value={phoneNumber}
                  onChange={setPhoneNumber}
                  className="justify-center"
                />
                <div id="recaptcha-container" ref={captchaRef}></div>
                <button
                  onClick={onSendOTP}
                  className="bg-emerald-600 w-full flex gap-1 items-center justify-center py-2.5 text-white rounded"
                >
                  {loading && (
                    <FaSpinner size={20} className="mt-1 animate-spin" />
                  )}
                  <span>Send OTP via SMS</span>
                </button>
              </>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
