import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { getAllJobs } from '../../apis/user';
import DefaultLayout from '../../components/DefaultLayout';

import Card from '../../components/JobGiver/Card'

function Jobs() {
  const [jobs, setJobs] = useState([])

  // fetch all jobs
  useEffect(() => {
    (async () => {
      const dbResponse = await getAllJobs();
      if (dbResponse.success) {
        setJobs(dbResponse.data)
      }
    })();
  }, [])

  const [searchTerm, setSearchTerm] = useState("");

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredJobs = jobs?.filter((item) =>
    item.tags.some((tag) => tag.includes(searchTerm))
  );
  return (
    <>
      <DefaultLayout />
      <Toaster toastOptions={{ duration: 4000 }} />
      <div className='mx-5 md:mx-40 my-10'>
        <div className="max-w-7xl mx-auto mb-6">
          <label htmlFor="search" className="">
            Search
          </label>
          <input
            type="text"
            id="search"
            placeholder="Search by tag"
            value={searchTerm}
            onChange={handleChange}
            className="border border-gray-300 rounded-md py-2 px-4 w-full"
          />
        </div>

        <div className='space-y-4 max-w-7xl mx-auto'>
          {searchTerm === "" ? (
            jobs?.reverse().map((profile, index) => <Card key={index} data={profile} />)
          ) : (
            filteredJobs?.reverse().map((profile, index) => <Card key={index} data={profile} />)
          )}

        </div>
      </div>

    </>


  )
}

export default Jobs