import Footer from "../../components/Footer";
import NavLogOut from "../../components/NavLogOut";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { Carousel1 } from "../../components/Carousel/Carousel1";
import { Carousel2 } from "../../components/Carousel/Carousel2";
import { Carousel3 } from "../../components/Carousel/Carousel3";

const HomePage = () => {
  // check - http://react-responsive-carousel.js.org/storybook/index.html?path=/story/01-basic--base for carousel
  return (
    <>
      <NavLogOut />
      <div className="md:mx-[60px]">
        <Carousel
          showIndicators={false}
          useKeyboardArrows={true}
          infiniteLoop={true}
          stopOnHover={true}
          autoPlay={true}
          showStatus={false}
          showArrows={true}
          showThumbs={false}
          interval={4000}
        >
          <div>
            <Carousel1 />
          </div>
          <div>
            <Carousel2 />
          </div>
          <div>
            <Carousel3 />
          </div>
        </Carousel>
      </div>

      <Footer />
    </>
  );
};
export default HomePage;
