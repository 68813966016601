export const Carousel2 = () => {
  return (
    <div className="flex flex-col items-center mx-1 ">
      <h1 className="text-gray-800 text-4xl text-center mt-4">
        Currently connecting <b>100+</b> of Individuals to Opportunities on Our
        Job Portal!
      </h1>
      <div className="w-full max-w-md mt-10">
        <img
          src="logo.jpeg"
          alt="Banner for Ritanya Sanshta job portal"
          className="w-full"
        />
      </div>
    </div>
  );
};
