import React from "react";
import { useState } from "react";

import ShowJobGiverData from "./ShowJobGiverData";
import { Toaster, toast } from "react-hot-toast";
import JobPostForm from "./JobPostForm";
import { updateDocument } from "../../apis/user";
// const MAX_LIMIT = 5;

function JobGiverProfile({ profiles, name }) {
  const [isOpen, setIsOpen] = useState(false);

  const [apiData, setApiData] = useState(profiles || []);

  const handleFormSubmit = async (formData) => {
    setApiData((prevData) => [...prevData, formData]); // push formValues into apiData array
    try {
      const response = await updateDocument([...apiData, formData]);
      if (response.success) {
        toast.success(response.message);
      } else {
        toast.error(response.message);
        console.log(response.message);
      }
    } catch (error) {
      toast.error(error.message);
      console.log(error.message);
    }
  };

  const deleteprofileHandler = async (index) => {
    const newData = [...apiData];
    newData.splice(index, 1);
    setApiData(newData);
    try {
      await updateDocument(newData);
    } catch (error) {
      toast.error(error.message);
      console.error("Error deleting job seeker:", error);
    }
  };
  const combinedData = [...apiData];

  return (
    <>
      <Toaster toastOptions={{ duration: 4000 }} />
      {!isOpen && (
        <div
          className={`${isOpen ? "blur-lg " : ""} max-w-7xl md:mx-auto mx-2`}
        >
          <h2 className="mb-2 ml-2 font-semibold">Welcome {name}</h2>
          <button
            className="ml-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-10 "
            onClick={() => setIsOpen(true)}
          >
            Post your Jobs here
          </button>

          <ShowJobGiverData
            profiles={combinedData}
            onDelete={deleteprofileHandler}
          />
        </div>
      )}
      {isOpen && (
        <JobPostForm
          isOpen={isOpen}
          onSubmit={handleFormSubmit}
          onClose={() => {
            setIsOpen(false);
          }}
        />
      )}
    </>
  );
}

export default JobGiverProfile;
