import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDocB-eY2uUfNlYv4YS4SgmPg4rGtT_648",
  authDomain: "ritanya-sanstha-job-board.firebaseapp.com",
  projectId: "ritanya-sanstha-job-board",
  storageBucket: "ritanya-sanstha-job-board.appspot.com",
  messagingSenderId: "934004284209",
  appId: "1:934004284209:web:c741b7d1ffe624af0fee10",
  measurementId: "G-MYZRDL7Z61",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize Cloud Firestore and get a reference to the service
export const fireDB = getFirestore(app);
//  extract auth method from firebase
//  export const auth = getAuth();
export const auth = getAuth(app);

// Initialize Cloud Storage and get a reference to the service
export const storage = getStorage(app);